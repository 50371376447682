import React, { useState, useEffect } from "react";
import "../../assets/css/Blogs.css";
import { blogs } from "../../assets/Images/ImagesLink";
import BlogsNav from "./BlogsNav";
import { Col, Row } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "../../API/axios";
import { any } from "prop-types";
import { Helmet } from "react-helmet";
import { ShimmerContentBlock } from "react-shimmer-effects";

const BlogDetailPage = () => {
  const [tab, setTab] = useState("");
  const slug = window.location.href.split("blogs/")[1];

  const [blogdetaildata, setBlogdetaildata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getblogdetailbytitle = () => {
    try {
      axios
        .get(
          `Blog/GetBlogByslugURLvalue/${slug}`,
          {}
        )
        .then((res) => {
          setBlogdetaildata(res.data);
          console.log("res.data.shortDescription", res.data);
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", res.data.shortDescription);
          document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", res.data.keywords, res.data.blogTagsNames);
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", res.data.blgSlug);
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", res.data.shortDescription);

          setIsLoading(false);
          window.scrollTo(0, 0);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getblogdetailbytitle();
  }, [slug]);


  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    // Cleanup script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>

      <div className="blog-detail-page-container">
        {blogdetaildata && (
          <>
            <Helmet>
              <title>{blogdetaildata.headerTitle}</title>
              <meta
                name="description"
                content={blogdetaildata.shortDescription}
              />
              <meta
                name="keywords"
                content={`${blogdetaildata.keywords}, ${blogdetaildata.blogTagsNames}`}
              />
              <meta property="og:title" content={blogdetaildata.blgSlug} />
              <meta
                property="og:description"
                content={blogdetaildata.shortDescription}
              />
            </Helmet>
            <BlogsNav setTab={setTab} tab={tab} />

            <div
              className="blog-details-page"
              style={{ visibility: isLoading ? "hidden" : "visible" }}
            >
              <Row className="justify-content-center my-5" style={{ width: '100%' }}>
                <Col className="px-3" xs={12} md={7}>
                  <div className="border-bottom py-2 mt-2">
                    <div className="d-flex align-items-center nav-text">
                      <NavLink to="/blogs">
                        <span className="p-0 m-0 ">All Blogs</span>
                      </NavLink>
                      <span>&nbsp; / &nbsp;</span>
                      <NavLink
                        to={"/blogs/category/" + blogdetaildata.bgCtgryName}
                      >
                        <span className="p-0 m-0">
                          {blogdetaildata.bgCtgryName}
                        </span>
                      </NavLink>
                    </div>
                    <h1 className="my-1 blog-detail-heading detail-main-text">
                      {blogdetaildata.headerTitle}
                    </h1>
                    <NavLink to="">
                      <span className="p-0 m-0 nav-text">{blogdetaildata.author}</span>
                    </NavLink>
                    <Row className="align-items-center mt-1 nav-text" style={{ width: '100%' }}>
                      <Col xs={12} md={9}>
                        <span>{blogdetaildata.blogDate}</span>
                      </Col>
                    </Row>
                  </div>
                  <Row style={{ width: '100%' }}>
                    <img
                      src={blogdetaildata.image}
                      alt={blogdetaildata.imageAltTitle} width={100}
                    />
                  </Row>
                  <div className="border-bottom py-2 blog-details-desc">
                    <p>
                      {blogdetaildata.description && (
                        // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html: blogdetaildata.description.replace(
                        //       /<span[^>]*>|<\/span>|&nbsp;/g,
                        //       ""
                        //     ),
                        //   }}
                        // ></div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: blogdetaildata.description
                              .replace(/<span[^>]*>|<\/span>|&nbsp;/g, "")
                              .replace(/style="[^"]*"/g, ""), // removes inline styles
                          }}
                        ></div>
                      )}
                    </p>
                  </div>

                  {blogdetaildata &&
                    blogdetaildata.combinedRelatedBlogs &&
                    blogdetaildata.combinedRelatedBlogs.map(
                      (relatedblogitem) => {
                        return (
                          <div className="border-bottom py-2 related-articles">
                            <Row className="align-items-center" style={{ width: '100%' }}>
                              <h1 className="my-2 blog-detail-heading">
                                Related Blogs
                              </h1>
                              <Row className="m-0" style={{ width: '100%' }}>
                                <Col
                                  className="related-articles-desc"
                                  xs={{ size: 12, order: 2 }}
                                  md={{ size: 12, order: 2 }}
                                  lg={{ size: 6, order: 1 }}
                                >
                                  <NavLink className="WLJ-product-title" to="">
                                  </NavLink>
                                  <Link
                                    onClick={getblogdetailbytitle}
                                    to={
                                      "/blogs/" + relatedblogitem.slugURLvalue
                                    }
                                  >
                                    <p className="article-title pt-1 pb-3 pt-lg-2 pb-lg-4 m-0">
                                      {relatedblogitem.headerTitle}
                                    </p>
                                  </Link>
                                  <p className="article-name-date">
                                    {relatedblogitem.author} |{" "}
                                    <span>{relatedblogitem.blogDate}</span>
                                  </p>
                                </Col>
                                <Col
                                  // onMouseOver={true}
                                  className="mb-1 mb-lg-2"
                                  xs={{ size: 12, order: 1 }}
                                  md={{ size: 12, order: 1 }}
                                  lg={{ size: 6, order: 2 }}
                                >
                                  <Link
                                    onClick={getblogdetailbytitle}
                                    to={
                                      "/blogs/" + relatedblogitem.slugURLvalue
                                    }
                                  >
                                    <img src={relatedblogitem.thumbnail} width={100} />
                                  </Link>
                                </Col>
                              </Row>
                            </Row>
                          </div>
                        );
                      }
                    )}
                </Col>
              </Row>
            </div>
          </>
        )}
        {isLoading && (
          <div className="text-center">
            <ShimmerContentBlock
              title
              text
              cta
              thumbnailWidth={370}
              thumbnailHeight={370}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BlogDetailPage;
