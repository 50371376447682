import React, { useEffect, useRef, useState } from "react";
import { NewsFeed, Profile } from "../../assets/Images/ImagesLink";
import "../../assets/css/PersonalProfile.css";
import { Link, NavLink, useParams } from "react-router-dom";
import PersonalPost from "./PersonalPost";
import Followers from "./Followers";
import Following from "./Following";
import Likes from "./Likes";
import Photos from "./Photos";
import Videos from "./Videos";
import Saved from "./Saved";
import About from "./About";
import axios from "../../API/axios";
import toast from "react-hot-toast";
import ErrorToast from "../ErrorToast";
import WarningToast from "../WarningToast";
import SuccessToast from "../SuccessToast";
import Select from "react-select";
import { userAuthImage } from "../../assets/Images/ImagesLink";
import "cropperjs/dist/cropper.css";
import Cropper from "react-easy-crop";
import Mutuals from "./Mutuals";
import Follows from "./Follows";
import { ShimmerThumbnail } from "react-shimmer-effects";
import getCroppedImg from "./cropImage";
import { dataURLtoFile } from "./dataUrlToFile";
import ReactCropperComp from "./ReactCropperComp";
import { Button } from "react-bootstrap";
import ImageCropDialog from "./ImageCropDialog";
import ProfileBackground from "./ProfileBackground";
import FollowingTab from "./FollowingTab";
import { ShimmerDiv } from "shimmer-effects-react";
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';



const PersonalProfile = ({ postList }) => {
  const guestModeData = localStorage.getItem("guestMode");
  const sessionId = localStorage.getItem("sessionId");
  const { uid } = useParams();
  const userUid = localStorage.getItem("userUid");
  const [userData, setUserData] = useState([]);
  const [userStat, setUserStat] = useState([]);
  console.log("userData?.profileInfo?", userData);
  //   State for Toasters
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [openBgModal, setOpenBgModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);

  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);

  const cropperRef = useRef(null);
  const cropperRef1 = useRef(null);

  const handleBgOpen = (event) => {
    event.stopPropagation();
    setOpenBgModal(true);
  };
  const [isloading, setIsloading] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const getUserProfile = () => {
    setIsloading(true);
    const data = {
      deviceType: "Web",
      userUid: uid,
    };
    axios
      .post(`UserProfile/GetUserProfile`, data)
      .then((response) => {
        console.log('responseData', response);
        if (response.status === 204) {
          setErrorImage(true)
        } else {
          const res = response.data.responseData;
          setUserData(res);
          setIsloading(false);
        }
        // setEducationinfo(res.viewEducationInfo)
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };
  useEffect(() => {
    getUserProfile();
    setActiveTab(1);
  }, [uid]);

  console.log("userprofilehgjdshghjd", userData);

  //************************User Profile Get End  ************************//

  //************************User Profile Stats Start  ************************//
  const [showDeletePost, setShowDeletePost] = useState(false);
  const getUserProfileStat = () => {
    const data = {
      deviceType: "Web",
      userUid: uid,
    };
    axios
      .post(`UserProfile/GetUserProfileStats`, data)
      .then((response) => {
        console.log("userprofilestat", response.data.responseData);
        const res = response.data.responseData;
        setUserStat(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProfileStat();
  }, [uid, showDeletePost]);

  //************************User Profile Stats End  ************************//

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //************************ Website   ************************//

  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "0", label: "None" },
    { value: "1", label: "ToWork" },
    { value: "2", label: "ToHire" },
  ];

  useEffect(() => {
    // Set the initial selected option based on userData
    if (userData?.openInfo?.type) {
      const initialOption = options.find(
        (option) => option.label === userData?.openInfo.type
      );
      setSelectedOption(initialOption);
    }
  }, [userData]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setOptionsValue(selectedOption.value);
    setOptionsLabel(selectedOption.label);
  };

  const [optionsValue, setOptionsValue] = useState(null);
  const [optionsLabel, setOptionsLabel] = useState("");
  console.log(optionsValue, optionsLabel);
  const [openToModal, setOpenToModal] = useState(false);
  const [mutualCounts, setMutualCounts] = useState(0);

  const handleopenToModal = () => {
    setOpenToModal(false);
  };

  const OpenTo = async () => {
    try {
      if (optionsLabel !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("Type", optionsLabel);
        formData.append("VisibilityType", optionsValue);
        const res = await axios.post("UserAccount/SetOpenTo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setToastMessage(res.data.message);
        setShowToast(true);
        setOpenToModal(false);
        getUserProfile();
      } else {
        toast.error("Please Select!");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ Website  End ************************//
  const [activeTab, setActiveTab] = useState(1);

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <About
            userData={userData}
            uid={uid}
            getUserProfile={getUserProfile}
          />
        );
      case 2:
        return (
          <PersonalPost uid={uid} getUserProfileStat={getUserProfileStat} setShowDeletePost={setShowDeletePost} showDeletePost={showDeletePost} />
        );
      case 3:
        return <Followers uid={uid} getUserProfileStat={getUserProfileStat} />;
      case 4:
        return (
          <FollowingTab
            uid={uid}
            getUserProfileStat={getUserProfileStat}
            userData={userData}
            getUserProfile={getUserProfile}
          />
        );
      case 5:
        return <Likes />;
      case 6:
        return <Photos />;
      case 7:
        return <Videos />;
      case 8:
        return <Saved getUserProfileStat={getUserProfileStat} />;
      case 9:
        return (
          <Mutuals
            uid={uid}
            getUserProfileStat={getUserProfileStat}
            setMutualCounts={setMutualCounts}
            mutualCounts={mutualCounts}
            fetchShortMutualList={fetchShortMutualList}
          />
        );
      case 10:
        return <Follows uid={uid} getUserProfileStat={getUserProfileStat} />;
      default:
        return null;
    }
  };

  const handleOpenBgCloseModal = () => {
    setOpenBgModal(false);
  };

  const handleOpenProfileCloseModal = () => {
    setOpenProfileModal(false);
  };

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleScroll();
  }, []);

  console.log("selected image", image);
  const [shortMutualList, setShortMutualList] = useState([]);
  const fetchShortMutualList = async () => {
    // console.log('connect data', id);
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUid: uid,
    };
    try {
      const response = await axios.post(
        "UserFollow/GetShortMutualUserInfo",
        data
      );
      console.log("response?.data?.responseData", response?.data?.responseData);

      // setShortMutualList(prevState => ({
      //   ...prevState,
      // }));
      setShortMutualList(response?.data?.responseData);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // setIsError(true);
      } else {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchShortMutualList();
  }, [uid]);

  console.log("shortMutualList", shortMutualList);


  useEffect(() => {
    if (showDeletePost) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [showDeletePost]);

  //************************ Profile Picture   ************************//
  // const [selectedprofileImage, setselectedprofileImage] = useState(null);
  // const [profileImage, setprofileImage] = useState(null);
  // // console.log(profileImage);
  // const [profileModal, setprofileModal] = useState(false);
  // const handleprofileModal = () => {
  //   setprofileModal(false);
  // };
  // const fileInputRefprofile = useRef(null);
  // const handleProfileClick = () => {
  //   fileInputRefprofile.current.click();
  // };
  // const handleProfileImageChange = (event) => {
  //   const selectedFile = event.target.files[0];

  //   setprofileImage(selectedFile);
  //   // setprofileImage(event.target.files[0].name);
  //   setselectedprofileImage(URL.createObjectURL(selectedFile));
  // };

  // const ProfileImageUpload = async () => {
  //   localStorage.removeItem('user-profilPic')
  //   localStorage.removeItem('profilPic')
  //   try {
  //     if (profileImage !== null) {
  //       const formData = new FormData();
  //       formData.append("DeviceType", "Web");
  //       formData.append("sessionId", sessionId);
  //       formData.append("ProfilPic", profileImage);
  //       const res = await axios.post("/UserAccount/SetProfilePic", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       console.log("response: ", res.data.responseData);
  //       // toast.success(res.data.message);
  //       setToastMessage(res.data.message);
  //       setShowToast(true);
  //       setprofileModal(false);
  //       getUserProfile()
  //       localStorage.setItem("user-profilPic", res.data.responseData);
  //       localStorage.setItem("profilPic", res.data.responseData);

  //       // userConfig();
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 404) {
  //       setWarningToastMessage(error.response.data.message);
  //       setShowWarningToast(true);
  //     } else {
  //       setErrorToastMessage(error.response.data.message);
  //       setShowErrorToast(true);
  //     }
  //   }
  // };
  //************************ Profile Picture End  ************************//

  //************************ Profile Picture ************************//
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileModal, setProfileModal] = useState(false);

  const fileInputRefProfile = useRef(null);

  const handleProfileClick = () => {
    fileInputRefProfile.current.click();
  };

  const handleProfileImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setProfileImage(selectedFile);
    setSelectedProfileImage(URL.createObjectURL(selectedFile));
    setProfileModal(true); // Open the modal after selecting the image
  };

  const handleProfileModalClose = () => {
    setProfileModal(false);
  };

  const ProfileImageUpload = async () => {
    localStorage.removeItem("user-profilPic");
    localStorage.removeItem("profilPic");
    try {
      if (profileImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("ProfilPic", profileImage);
        const res = await axios.post("/UserAccount/SetProfilePic", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("response: ", res.data.responseData);
        setToastMessage(res.data.message);
        setShowToast(true);
        setProfileModal(false);
        getUserProfile();
        // window.localStorage.setItem("user-profilPic", res.data.responseData);
        // window.localStorage.setItem("profilPic", res.data.responseData);
        // Store in localStorage
        const newImageUrl = res.data.responseData;
        window.localStorage.setItem("user-profilPic", newImageUrl);
        window.localStorage.setItem("profilPic", newImageUrl);

        // Dispatch a custom event
        window.dispatchEvent(
          new CustomEvent("profilePicUpdated", {
            detail: newImageUrl,
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Profile Picture End ************************//

  //************************ Background Image   ************************//
  const [bgSelectedImage, setBgSelectedImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  // console.log(backgroundImage);
  const [bgModal, setBgModal] = useState(false);
  const handleBgModal = () => {
    setBgModal(false);
  };
  const fileInputRefBg = useRef(null);
  const handleBgClick = () => {
    fileInputRefBg.current.click();
  };
  const handleBgImageChange = (event) => {
    const selectedFile = event.target.files[0];
    // setBackgroundImage(event.target.files[0].name);
    setBackgroundImage(selectedFile);
    setBgSelectedImage(URL.createObjectURL(selectedFile));
    setBgModal(true);
  };

  const BgImageUpload = async () => {
    try {
      if (backgroundImage !== null) {
        const formData = new FormData();
        formData.append("DeviceType", "Web");
        formData.append("sessionId", sessionId);
        formData.append("BackGroundImage", backgroundImage);
        const res = await axios.post(
          "/UserAccount/SetBackgroundImage",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("response: ", res.data.responseData);
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        setBgModal(false);
        getUserProfile();
        // userConfig();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  //************************ Background Image End  ************************//

  useEffect(() => {
    if (showModal || profileModal || openProfileModal || openBgModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal, profileModal, openProfileModal, openBgModal]);

  useEffect(() => {
    if (userData) {
      console.log("userData?.openInfo?.type", userData?.openInfo?.type);
      setOptionsLabel(userData?.openInfo?.type);
    }
  }, [userData]);


  const [userPer, setUserPer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoadingalert, setIsLoadingalert] = useState(true); // Add loading state

  const getUserProfilePer = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`UserProfile/GetProfilePercentage`, data)
      .then((response) => {
        const res = response.data.responseData;
        setUserPer(res.percentage);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingalert(false); // Set loading to false once the data is fetched
      });
  };

  useEffect(() => {
    getUserProfilePer();
  }, [sessionId]);

  // Normalize userPer to a number, defaulting to 0 if it is an empty array or non-numeric
  const normalizedUserPer = Array.isArray(userPer) ? (userPer.length === 0 ? 0 : Number(userPer[0])) : Number(userPer);

  // Condition to show the alert
  const shouldShowAlert = !isLoadingalert && normalizedUserPer <= 59 && alert === false && guestModeData === null;
  const adInitialized = useRef(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    if (!adInitialized.current) {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
      adInitialized.current = true;
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {userData?.profileInfo?.isOwn === true ? shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3 mt-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      ) : ''}
      {/* {shouldShowAlert && (
        <div className='p-3 closeAccount m-0 mb-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon
                style={{ width: '20px' }}
                className="pointer"
                onClick={() => setAlert(true)} // Dismiss the alert
              />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have a valid resume to apply for a job</span>
          </div>
          <div className="mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}
              >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>
      )} */}
      {/* {userData?.profileInfo?.isOwn === true ? (guestModeData === 'false' || guestModeData === null) && userPer <= 60 && alert === false && (
        <div className='p-3 closeAccount m-0 my-3'>
          <div className='d-flex mb-3 align-items-center'>
            <InfoSharpIcon />
            <span className='cardHeader ms-1'>Announcement!</span>
            <span className="ms-auto">
              <CloseSharpIcon style={{ width: '20px' }} className="pointer" onClick={() => {
                setAlert(true);
              }} />
            </span>
          </div>
          <div>
            <span>Please complete your profile up to 60% and must have valid resume to apply for job.</span>

          </div>

          <div className=" mt-2">
            <Link to={`/User/${userUid}`}>
              <button
                color=""
                className="closebtn"
                style={{ width: "150px" }}  >
                Manage Profile
              </button>
            </Link>
          </div>
        </div>) : ''} */}

      {errorImage ? <div className="mt-2 text-center">
        <img
          src={`https://welinkjobs.com/Images/Default%20Images/404/PNG/No%20Result%202.png`}
          alt="404 Not Found"
          width={'500px'}
        />
      </div> : <div className="ProfileInner">
        {isloading ? (
          <>
            <ShimmerDiv mode="light" height={280} width={"100%"} />
          </>
        ) : (
          <>
            <div className="card profileCard desktopView mt-3">
              <div className="Profilebackground">
                {/* <img src={Profile.Background} alt="" /> */}
                <div
                  className="background-overlay"
                  onClick={(event) => setOpenBgModal(true)}
                >
                  <img
                    src={userData?.profileInfo?.backGroundImage}
                    alt=""
                    height={200}
                  />
                  {userUid === uid && (
                    <>
                      <label
                        htmlFor="fileInput"
                        className="backgroundimg-edit"
                        onClick={(event) => {
                          event.stopPropagation();
                          // setBgModal(true);
                          handleBgClick();
                        }}
                      >
                        <EditSharpIcon className="material-symbols-sharp" />
                      </label>
                    </>
                  )}
                  {/* {userUid === uid && (
                    <>
                      <StyledDemo />
                    </>
                  )} */}
                </div>
                <input
                  type="file"
                  ref={fileInputRefBg}
                  style={{ display: "none" }}
                  onChange={handleBgImageChange}
                  accept="image/*"
                />
              </div>

              <div
                className="profileImg"
                onClick={(event) => {
                  setOpenProfileModal(true);
                }}
              >
                <img src={userData?.profileInfo?.profilPic} alt="" />
                {userUid === uid && (
                  <label
                    htmlFor="fileInput"
                    className="background-edit"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleProfileClick();
                    }}
                  >
                    <EditSharpIcon className="material-symbols-sharp" />
                  </label>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRefProfile}
                style={{ display: "none" }}
                onChange={handleProfileImageChange}
                accept="image/*"
              />

              <div
                className="profileDetails pointer"
                onClick={(event) => setOpenBgModal(true)}
              >
                <h4 onClick={(e) => e.stopPropagation()}>
                  {userData?.profileInfo?.firstName}{" "}
                  {userData?.profileInfo?.lastName}{" "}
                </h4>
                <div
                  className="d-flex align-items-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <a href="#/">{userData?.profileInfo?.nameTag}</a>{" "}
                  <div className=" badge-vip  ">
                    {uid}{" "}
                  </div>
                </div>
                <p onClick={(e) => e.stopPropagation()}>
                  {userData?.profileInfo?.headLine}
                </p>
                <p onClick={(e) => e.stopPropagation()}>
                  {userData?.profileInfo?.title}
                </p>
                <p onClick={(e) => e.stopPropagation()}>
                  {userData?.profileInfo?.cityName &&
                    `${userData?.profileInfo?.cityName},`}
                  {userData?.profileInfo?.countryName &&
                    ` ${userData?.profileInfo?.countryName}`}
                </p>
                {userData?.profileInfo?.isOwn === true ? (
                  ""
                ) : (
                  <div
                    className="d-flex mt-1 ms-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="circle-container">
                      {shortMutualList?.pics?.map((pic, picIndex) => (
                        <img
                          src={pic}
                          alt={`Circle ${picIndex + 1}`}
                          //    className="circle-img"
                          key={picIndex}
                          onClick={() => setActiveTab(9)}
                        />
                      ))}
                    </div>
                    {console.log('shortMutualList', shortMutualList)}

                    <div className={`f-12 ${shortMutualList?.count === 1 ? 'ms-0' : 'ms-4'}`} onClick={() => setActiveTab(9)}>
                      {shortMutualList?.text}
                    </div>
                  </div>
                )}
              </div>

              <div className="profileHeader">
                <div className=" d-flex align-items-center">
                  {guestModeData === "true" ? (
                    ""
                  ) : (
                    <div className="d-flex">
                      {uid === userUid ? (
                        userData?.openInfo?.type === "ToHire" ? (
                          <button
                            className="gradientBtn me-3 ms-3"
                            onClick={() => {
                              setOpenToModal(true);
                            }}
                          >
                            #Hiring
                          </button>
                        ) : userData?.openInfo?.type === "ToWork" ? (
                          <button
                            className="gradientBtn me-3 ms-3"
                            onClick={() => {
                              setOpenToModal(true);
                            }}
                          >
                            #OpenToWork
                          </button>
                        ) : (
                          <button
                            className="gradientBtn me-3 d-flex align-items-center"
                            onClick={() => {
                              setOpenToModal(true);
                            }}
                          >
                            <AddCircleSharpIcon className="material-symbols-sharp"
                            />
                            OpenToWork
                          </button>
                        )
                      ) : userData?.openInfo?.type === "ToHire" ? (
                        <button className="gradientBtn me-3 ms-3">#Hiring</button>
                      ) : userData?.openInfo?.type === "ToWork" ? (
                        <button className="gradientBtn me-3 ms-3">
                          #OpenToWork
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <div className="tabButton d-flex ms-5 w-100 me-2">
                    <NavLink
                      onClick={() => setActiveTab(1)}
                      className={`text-center aboutTab me-4 ${activeTab === 1 ? "activeTab" : ""
                        }`}
                    >
                      <p className="f-12 tabName">About</p>
                    </NavLink>
                    <NavLink
                      onClick={() => setActiveTab(2)}
                      className={`text-center  me-4 ${activeTab === 2 ? "activeTab" : ""
                        }`}
                    >
                      <p className="f-12 tabName">Post</p>
                      <p className="f-14 tabCount">{userStat?.postsCount}</p>
                    </NavLink>
                    <NavLink
                      onClick={() => setActiveTab(3)}
                      className={`text-center  me-4 ${activeTab === 3 ? "activeTab" : ""
                        }`}
                    >
                      <p className="f-12 tabName">Followers</p>
                      <p className="f-14 tabCount">{userStat?.followersCount}</p>
                    </NavLink>
                    <NavLink
                      onClick={() => setActiveTab(4)}
                      className={`text-center  me-4 ${activeTab === 4 ? "activeTab" : ""
                        }`}
                    >
                      <p className="f-12 tabName">Following</p>
                      <p className="f-14 tabCount">{userStat?.followingCount}</p>
                    </NavLink>
                    {guestModeData === "true" ||
                      (userData?.profileInfo?.isOwn === false && (
                        <NavLink
                          onClick={() => setActiveTab(9)}
                          className={`text-center  me-4 ${activeTab === 9 ? "activeTab" : ""
                            }`}
                        >
                          <p className="f-12 tabName">Mutual Connections</p>
                          <p className="f-14 tabCount">
                            {shortMutualList === null
                              ? "0"
                              : shortMutualList?.count}
                          </p>
                        </NavLink>
                      ))}
                    {userData?.profileInfo?.isOwn === true && (
                      <NavLink
                        onClick={() => setActiveTab(8)}
                        className={`text-center  me-4 ${activeTab === 8 ? "activeTab" : ""
                          }`}
                      >
                        <p className="f-12 tabName">Saved</p>
                        <p className="f-14 tabCount">
                          {userStat?.savedPostsCount}
                        </p>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* For Mobile view */}

            <div className="card profileCard mobileView">
              <div className="Profilebackground">
                {/* <img src={Profile.Background} alt="" /> */}
                <div
                  className="background-overlay"
                  onClick={(event) => setOpenBgModal(true)}
                >
                  <img
                    src={userData?.profileInfo?.backGroundImage}
                    alt=""
                    height={200}
                  />
                  {userUid === uid && (
                    <>
                      <label
                        htmlFor="fileInput"
                        className="backgroundimg-edit"
                        onClick={(event) => {
                          event.stopPropagation();
                          setBgModal(true);
                        }}
                      >
                        <EditSharpIcon className="material-symbols-sharp" />
                      </label>
                    </>
                  )}
                </div>
              </div>

              <div
                className="profileImg"
                onClick={(event) => {
                  setOpenProfileModal(true);
                }}
              >
                <img src={userData?.profileInfo?.profilPic} alt="" />
                {userUid === uid && (
                  <label
                    htmlFor="fileInput"
                    className="background-edit"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleProfileClick();
                    }}
                  >
                    <EditSharpIcon className="material-symbols-sharp" />
                  </label>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRefProfile}
                style={{ display: "none" }}
                onChange={handleProfileImageChange}
                accept="image/*"
              />

              <div className="profileDetails-mb pt-4  p-2 text-center">
                <h4>
                  {userData?.profileInfo?.firstName}{" "}
                  {userData?.profileInfo?.lastName}{" "}
                </h4>
                <div className="d-flex align-items-center justify-content-center">
                  <a href="#/">{userData?.profileInfo?.nameTag}</a>{" "}
                  <div className="badge badge-vip">
                    {uid}{" "}
                  </div>
                </div>
                <p>{userData?.profileInfo?.headLine}</p>
                <p>{userData?.profileInfo?.title}</p>
                <p>
                  {userData?.profileInfo?.cityName &&
                    `${userData?.profileInfo?.cityName},`}
                  {userData?.profileInfo?.countryName &&
                    ` ${userData?.profileInfo?.countryName}`}
                </p>
                <div className="d-flex mt-1 ms-2">
                  <div className="circle-container">
                    {shortMutualList?.pics?.map((pic, picIndex) => (
                      <img
                        src={pic}
                        alt={`Circle ${picIndex + 1}`}
                        className="circle-img"
                        key={picIndex}
                      />
                    ))}
                  </div>
                  {/* <div className="f-12" >{shortMutualList?.text}</div> */}
                  <div className={`f-12 ${shortMutualList?.count === 1 ? 'ms-0' : 'ms-4'}`} onClick={() => setActiveTab(9)}>
                    {shortMutualList?.text}
                  </div>
                </div>
              </div>

              <div className="profileHeader d-flex align-items-center flex-column flex-md-row">
                {guestModeData === "true" ? (
                  ""
                ) : (
                  <div className="d-flex">
                    {uid === userUid ? (
                      userData?.openInfo?.type === "ToHire" ? (
                        <button
                          className="gradientBtn me-3 ms-3"
                          onClick={() => {
                            setOpenToModal(true);
                          }}
                        >
                          #Hiring
                        </button>
                      ) : userData?.openInfo?.type === "ToWork" ? (
                        <button
                          className="gradientBtn me-3 ms-3"
                          onClick={() => {
                            setOpenToModal(true);
                          }}
                        >
                          #OpenToWork
                        </button>
                      ) : (
                        <button
                          className="gradientBtn me-3 d-flex align-items-center"
                          onClick={() => {
                            setOpenToModal(true);
                          }}
                        >

                          <AddCircleSharpIcon className="material-symbols-sharp"
                          />
                          OpenToWork
                        </button>
                      )
                    ) : userData?.openInfo?.type === "ToHire" ? (
                      <button className="gradientBtn me-3 ms-3">#Hiring</button>
                    ) : userData?.openInfo?.type === "ToWork" ? (
                      <button className="gradientBtn me-3 ms-3">
                        #OpenToWork
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div className="tabButton d-flex ms-5 w-100 me-2">
                  <NavLink
                    onClick={() => setActiveTab(1)}
                    className={`text-center aboutTab me-4 ${activeTab === 1 ? "activeTab" : ""
                      }`}
                  >
                    <p className="f-12 tabName">About</p>
                  </NavLink>
                  <NavLink
                    onClick={() => setActiveTab(2)}
                    className={`text-center  me-4 ${activeTab === 2 ? "activeTab" : ""
                      }`}
                  >
                    <p className="f-12 tabName">Post</p>
                    <p className="f-14 tabCount">{userStat?.postsCount}</p>
                  </NavLink>
                  <NavLink
                    onClick={() => setActiveTab(3)}
                    className={`text-center  me-4 ${activeTab === 3 ? "activeTab" : ""
                      }`}
                  >
                    <p className="f-12 tabName">Followers</p>
                    <p className="f-14 tabCount">{userStat?.followersCount}</p>
                  </NavLink>
                  <NavLink
                    onClick={() => setActiveTab(4)}
                    className={`text-center  me-4 ${activeTab === 4 ? "activeTab" : ""
                      }`}
                  >
                    <p className="f-12 tabName">Following</p>
                    <p className="f-14 tabCount">{userStat?.followingCount}</p>
                  </NavLink>

                  {console.log("shortMutualList", shortMutualList)}

                  {guestModeData === "true" ||
                    (userData?.profileInfo?.isOwn === false && (
                      <NavLink
                        onClick={() => setActiveTab(9)}
                        className={`text-center  me-4 ${activeTab === 9 ? "activeTab" : ""
                          }`}
                      >
                        <p className="f-12 tabName">Mutual Connections</p>
                        <p className="f-14 tabCount">
                          {shortMutualList === null
                            ? "0"
                            : shortMutualList?.count}
                        </p>
                      </NavLink>
                    ))}
                  {userData?.profileInfo?.isOwn === true && (
                    <NavLink
                      onClick={() => setActiveTab(8)}
                      className={`text-center  me-4 ${activeTab === 8 ? "activeTab" : ""
                        }`}
                    >
                      <p className="f-12 tabName">Saved</p>
                      <p className="f-14 tabCount">{userStat?.savedPostsCount}</p>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row mt-3">
          <div className="col-md-8">
            {isloading ? (
              <>
                {/* <ShimmerThumbnail height={200} mode="light" /> */}
                <ShimmerDiv mode="light" height={200} width={"100%"} />
              </>
            ) : (
              <div>{renderTabContent()}</div>
            )}
          </div>
          <div className="col-md-4 newsfeedPosts">
            <div className="advertiseImage">
              {/* <img src={NewsFeed.Advertise} alt="" /> */}
              <div className="adContainer">
                <ins class="adsbygoogle"
                  style={{
                    display: "block",
                    width: "100%",
                    // maxWidth: "320px",
                    // minHeight: "50px",
                    margin: "0 auto",
                  }}
                  data-ad-client="ca-pub-8691062301030113"
                  data-ad-slot="9135916775"
                  data-ad-format="auto"
                  data-adtest="on"
                  data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="setting-header d-flex justify-content-between align-items-center">
                  <h6>Ak Khan's Dashboard</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={closeModal}
                  />
                </div>
                <div className="row p-4 dashboard">
                  <div className="row">
                    <div className="col-md-4">
                      <p className="f-12">Your profile QR Code</p>
                      <div className="mt-3 qrImage">
                        <img src={Profile.qr} alt="" className="qrcode" />
                        <img src={Profile.logo} alt="" className="qrLogo" />
                      </div>
                      <p className="f-12 mt-2">Your profile Info</p>
                      <div className="mt-2 pointer">
                        <img src={Profile.download} alt="" className="download" />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <p className="f-14">Post Analytics</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">All Posts by you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">This month by you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                      </div>
                      <p className="f-14 mt-2">Like Analytics</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Liked posts by you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Liked posts to you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                      </div>
                      <p className="f-14 mt-2">Comment Analytics</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Commented by you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Commented to you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                      </div>
                      <p className="f-14 mt-2">Share Analytics</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Shared by you</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="dashboard-card mt-2">
                            <p className="f-12">Shared your content</p>
                            <p className="f-14">1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {openBgModal && (
          <div
            className="modal fade show postAdd modal-lg"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
            size="lg"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Background view</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => handleOpenBgCloseModal()}
                  />
                </div>
                <div className="backrgound-profile-view d-flex justify-content-center">
                  <img src={userData?.profileInfo?.backGroundImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}

        {openProfileModal && (
          <div
            className="modal fade show postAdd modal-sm"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
            size="lg"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Profile view</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => handleOpenProfileCloseModal()}
                  />
                </div>
                <div className="p-2">
                  <div className="backrgound-profile-view d-flex justify-content-center">
                    <img src={userData?.profileInfo?.profilPic} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {openToModal && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Open To</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleopenToModal();
                    }}
                  />
                </div>

                <div className="col-md-12 mt-2">
                  <label htmlFor="about">Status </label>

                  <Select
                    value={options.filter((c) => c.label === optionsLabel)}
                    options={options}
                    onChange={(e) => {
                      setOptionsValue(e.value);
                      setOptionsLabel(e.label);
                    }}
                    // loadOptions={selectlanguage}
                    placeholder="Select Status"
                  />
                </div>

                <div className="col-md-12 text-end mt-4">
                  <button type="button" className="mainBtn1" onClick={OpenTo}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {profileModal && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Profile Pic</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={handleProfileModalClose}
                  />
                </div>
                <div className="d-flex">

                </div>
                <div className="uploaded-imagebg mt-2">
                  {selectedProfileImage && (
                    <img src={selectedProfileImage} alt="Selected" height={200} />
                  )}
                </div>
                <div className="col-md-12 text-end mt-4">
                  <button
                    type="button"
                    className="mainBtn1"
                    onClick={ProfileImageUpload}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {bgModal && (
          <div
            className="modal fade show postAdd"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog  modal-dialog-centered " role="document">
              <div className="modal-content d-flex justify-content-around p-2">
                <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                  <h6>Background Image</h6>
                  <CloseSharpIcon
                    className="material-symbols-sharp me-2 pointer"
                    onClick={() => {
                      handleBgModal();
                    }}
                  />
                </div>

                <div className=" mt-2">
                  {bgSelectedImage && (
                    <img src={bgSelectedImage} alt="Selected" height={200} width={'100%'} />
                  )}
                </div>

                <div className="col-md-12 text-end mt-4">
                  <button
                    type="button"
                    className="mainBtn1"
                    onClick={BgImageUpload}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <SuccessToast
          show={showToast}
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />

        <WarningToast
          show={showWarningToast}
          message={warningToastMessage}
          onClose={() => setShowWarningToast(false)}
        />

        <ErrorToast
          show={showErrorToast}
          message={ErrorToastMessage}
          onClose={() => setShowErrorToast(false)}
        />
      </div>}
    </>

  );
};

export default PersonalProfile;
