import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/newsFeed.css";
import AllPost from "./AllPost";
import FeedAdvertise from "./FeedAdvertise";
import UserAuth from "../Auth/UserAuth";
import { GetProfileInfo } from '../../Services/Service'
import axios from "../../API/axios";
import ModalComponent from "./ModalComponent";
import { Helmet } from "react-helmet";

const NewsFeed = () => {
  const [data, setData] = useState(null);
  const [showUserAuthModal, setShowUserAuthModal] = useState(false);
  const sessionId = localStorage.getItem('sessionId');

  const handleCloseUserAuthModal = () => {
    setShowUserAuthModal(false);
    document.body.classList.remove('modal-open'); // Re-enable scroll when modal is closed
  };

  const fetchData = async () => {
    try {
      const result = await axios.post(`/UserProfile/GetOwnInfo`, {
        sessionId,
        deviceType: "Web",
      });
      const Response = result.data.responseData;
      setData(Response);

      const hasModalShown = sessionStorage.getItem('hasModalShown');
      sessionStorage.setItem('hasModalShown', 'true');

      if (!hasModalShown && (Response?.dob === null || Response?.genderId === null || Response?.cityID === null)) {
        // Show the modal if user data is incomplete
        setShowUserAuthModal(true);
        document.body.classList.add('modal-open'); // Disable scroll when modal is open
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sessionId]);

  useEffect(() => {
    if (!showUserAuthModal) {
      document.body.classList.remove('modal-open'); // Ensure scroll is enabled if modal is closed
    }
  }, [showUserAuthModal]);


  const adInitialized = useRef(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8691062301030113";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    if (!adInitialized.current) {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
      adInitialized.current = true;
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>WeLink Jobs NewsFeed | Best Social Media Platform For Job Search</title>
        <meta name="description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
        {/* <meta name="keywords" content="react, meta tags, seo" /> */}
        <meta name="keywords" content="newsfeed, updates, latest news" />
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content="WeLink Jobs | Best Social Media Platform For Job Search" />
        <meta property="og:description" content="WeLink Jobs, one of the most trusted job search sites, empowering your career journey. Connect seamlessly for free job posting and social networking." />
        {/* <meta property="og:image" content="https://example.com/image.jpg" /> */}
        <meta property="og:url" content="https://welinkjobs.com/NewsFeeds" />
      </Helmet>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-8 newsfeedPosts">
            <AllPost />
          </div>
          <div className="col-md-4 feedAdvertise">
            <div className="feedfixedContainer">
              <div className="featuredScroll">
                <div className="adContainer">
                  <ins
                    className="adsbygoogle"
                    style={{
                      display: "block",
                      width: "100%",
                      maxWidth: "320px",
                      minHeight: "50px",
                      margin: "0 auto",
                    }}
                    data-ad-client="ca-pub-8691062301030113"
                    data-ad-slot="7349331462"
                    data-ad-format="auto"
                    data-adtest="on"
                    data-full-width-responsive="true"
                  ></ins>
                </div>


                {/* <FeedAdvertise /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="userModal">
        {showUserAuthModal && (
          <UserAuth
            onClose={handleCloseUserAuthModal}
            data={data}
            showUserAuthModal={showUserAuthModal}
            setShowUserAuthModal={setShowUserAuthModal}
            fetchData={fetchData}
          />
        )}
      </div>
    </>
  );
};

export default NewsFeed;
